/* Colours monochrome. */
$black: #000;
$black-translucent: rgba(0, 0, 0, 0.74);

$white: #fff;
$white-off-white: #fbfbfb;
$white-smoke: #e5e5e5;

$grey: #b4b4b4;
$grey-medium: #898989;
$grey-dark: #666666;
$grey-lightest: #f5f5f5;
$grey-lighter: #e5e5e5;
$grey-lighter-semi-translucent: rgba(229, 229, 229, 0.85);
$grey-lighter-mid-translucent: rgba(229, 229, 229, 0.5);
$grey-lighter-translucent: rgba(229, 229, 229, 0.3);
$grey-light-medium: #9b9b9b;
$grey-light: #ccc;
$grey: #b4b4b4;
$grey-medium: #898989;
$grey-dark: #666666;
$grey-darker: #212020;
$grey-darkest: #262626;
$grey-desaturated: #333030;
$grey-mid-dark: #3f3f3f;
$greyFooter: #A4A9AD;
$greyMainBody: #7D868C;

/* Colours. */
$red: #e25050;
$red-light: #ffa0a0;
$blue: #346bb3;
$green: #70b012;
$green-bright: #2ecc71;
$green-bright-light: #CBF2DB;
$yellow: #EFB412;
$yellow-light: #FAEFD4;
$orange: #df6e16;
$orange-dark: rgba(240, 102, 23, 0.3);

/* Primary, secondary and tertiary colours. */
$primary: #003643;
$secondary: #003643;
$accent: $blue;
$warn: $red;
$gold: #ddb34e;

/* Onboarding colours. */
$onboarding-logo-background: $primary;

/* Anchors. */
$anchor: $blue;
$anchor-hover: $primary;

/* Borders. */
$light-border: $grey-light;

/* Journey breadcrumbs. */
$breadcrumb-stage: $blue;
$breadcrumb-step: $grey-lightest;
$breadcrumb-step-active: $grey;
$breadcrumb-step-complete-check: $green-bright;

/* Background colour. */
$primary-background-color: $primary;
$app-background-colour: $white-off-white;
$on-hover-background-color: $grey-lighter-translucent;

/* Secure messages. */
$sent-message-color: $red;
$receive-message-color: $grey-light;

/* Navigation active colours. */
$nav-item-active: $grey;

/* Product banner colours. */
$isa: $primary;
$gia: $secondary;
$jisa: $accent;

/* Banner colours. */
$banner: #a4a7aa;

/* Overwrite colours with client specific colours. */
@import "client-colours";

/* Statuses. */
$complete: $green-bright;
$complete-bg: $green-bright-light;

$pending: $yellow;
$pending-bg: $yellow-light;

$incomplete: $red;
$incomplete-bg: $red-light;

/** Text, primary and secondary. */
$dark-primary-text: $primary;
$dark-secondary-text: $secondary;

/* API error. */
$error: $red-light;

/* Custom border colours. */
$border-colour: $grey-light;
$custom-border-card-font-colour: $red;
