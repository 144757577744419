$greyUpper: #F6F6F6;
$greyFooter: #A4A9AD;
$greyBackground: #7D868C;
$greyNavText: #5C6670;
$greyMainBody: #7D868C;
$darkGreyFont: #333E48;
$linkOrange: #BD4F19;
$balancedBlue: #4387CF;
$incomePink: #F48887;
$mcInRoypurple: #8A76B8;
$mcInRoyOrange: #FF6B00;
$mcInRoyWhite: #F2ECDF;
$inputBg: #F9F6EF;
$inputText: #000;

/** Client specific colours. */
$primary: $linkOrange;
$secondary: $greyNavText;
$blue: $linkOrange;

/* Anchors. */
$anchor: $primary;
$anchor-hover: $primary;

/* Background colour. */
$primary-background-color: $mcInRoyWhite;
$breadcrumb-stage: $linkOrange;
$breadcrumb-step: $greyFooter;
$breadcrumb-step-active: black;
$breadcrumb-step-complete-check: #072E27;

/* Onboarding background colour. */
$onboarding-logo-background: $mcInRoyWhite;
$breadcrub-background-color: $mcInRoyWhite;


/* Container border colours. */
$light-border: $greyUpper;

/* Input control colours. */
$input-background: $inputBg;
$checkbox-background: $linkOrange;
$input-text: $inputText;

$accent: #BD4F19;
