@import 'theme';
@import 'variables';
@import 'colours';

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  color: $black; // TODO(aled): Check what colour we should use for all text.
  background-color: $white-smoke;
  text-align: left;

  &.dashboard {
    background-color: #fbfbfb;
  }
}

button {
  font-family: $font-family;
}

.border-none {
  border: none;
}

h3,
.h3 {
  font-size: 1.75rem;
    font-family: 'TimesItalic' !important;
    font-style: italic;
  margin-bottom: 0.5rem;
  font-weight: 400 !important;
}

h1,
h2,
h4,
h5,
h6,
.h1,
.h2,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

/** default anchor colour/hover. */
a {
  color: $anchor;
  text-decoration: underline;

  &:hover {
    color: $anchor-hover;
  }
}

a.disabled {
  color: $grey-medium;
  cursor: default;
}

/** Unordered list item markers. */
ul li::marker {
  color: $primary;
  font-size: 2em;
}

.mat-raised-button {
  padding: 20px;
}

.container {
  max-width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.navbar {
  width: 100%;
}

/** background colours. */
.primary-background-colour {
  background-color: $primary-background-color;
}

.background-color {
  background-color: $white-smoke;
  overflow: visible;
}

.background-color-white {
  background-color: $white;
}

/** Border Classes */
.border-bottom {
  border-bottom: 2px solid $border-colour;
}

.border-radius {
  border-radius: $border-radius;
  background-color: transparent;
}

/** Background Classes */
.white-background {
  background-color: $white;
}

.overflow-y-auto {
  overflow-y: auto;
}

.cursor-pointer {
  cursor: pointer;
}

mat-checkbox.mat-checkbox-text-wrap {
  label.mat-checkbox-layout {
    white-space: normal;
  }
}

mat-radio-button.mat-radio-button-text-wrap {
  label.mat-radio-label {
    white-space: normal;
  }
}

/** Padding Classes */
.padding {
  padding: 10px;
}

.padding-top {
  padding-top: 10px;
}

.padding-bottom {
  padding-bottom: 10px;
}

.padding-right {
  padding-right: 10px;
}

.padding-left {
  padding-left: 10px;
}

/** Margin Classes */
.no-margin {
  margin: 0;
}

/** Mouse Cursor Class */
.cursor-pointer {
  cursor: pointer;
}

/** Text Position Class */
.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

/** Higher level flex items with min-width = auto, can prevent nested elements shrinking that have min-width = 0. */
.min-width {
  min-width: 0;
}

/** Highlighted background colour grey, this can be applied to the mat-card e.g when you want the mat-card to stand out. */
.highlight-light-grey {
  background-color: $grey-lighter;
}

/** Portfolio/Mandate status colours. */
.complete {
  color: $complete;
}

.pending {
  color: $pending;
}

.incomplete {
  color: $incomplete;
}

.complete-bg {
  @extend .complete;
  background-color: $complete-bg;
}

.pending-bg {
  @extend .pending;
  background-color: $pending-bg;
}

.incomplete-bg {
  @extend .incomplete;
  background-color: $incomplete-bg;
}

/* Mat chip statuses. */
.mat-chip.complete {
  @extend .complete-bg;
}

.mat-chip.pending {
  @extend .pending-bg;
}

.mat-chip.incomplete {
  @extend .incomplete-bg;
}

/** Used to provide margin between mat-icon and text within a mat-button, where the icon is left of the text.
    The button text node must be within an element e.g <span>button text</span> */
button {
  mat-icon:first-child:not(:only-child) {
    margin: 0 10px 0 0;
  }
}

/** Logo. */
.logo-toolbar-max-height {
  max-height: 64px;
}

.logo {
  max-width: 100px;
}

.indicator {
  display: block;
  width: 10px;
  height: 10px;
  margin-top: 3px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: $dark-primary-text;
}

// auto-fill
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  color: $dark-primary-text;
  transition: background-color 5000s ease-in-out 0s;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/** Alternative button class is used for the journey 'Save & Exit' button. */
.alt-button {
  font-weight: 700;
  color: $secondary;
  text-decoration: underline;
}

/** Used for when we want a button to look like an anchor. */
.anchor-styled-button {
  background: transparent;
  border: none;
  color: $anchor;
  cursor: pointer;
  font-size: inherit;
  padding: 1px 0;
  text-decoration: underline;

  &:hover {
    color: $anchor-hover;
  }
}

.font-weight-bold {
  font-weight: bold;
}

/** Alternative accent colour for root nav items. */
.nav-underline-accent {
  mat-ink-bar {
    background-color: $accent !important;
  }
}

/** Customizations for Material accordion and expansion panel */
.mat-accordion .mat-expansion-panel {
  box-shadow: none;
  border-radius: 0 !important;
}

/** Customizations for Material card removing box shadow and border radius. */
.mat-card {
  border-radius: 0 !important;
}

.mat-card:not([class*='mat-elevation-z']) {
  box-shadow: none;
}

/** Customizations for Material card giving it a custom border-radius and box shadow. */
.custom-mat-card.mat-card {
  border-radius: 10px !important;
}

.custom-mat-card.mat-card:not([class*='mat-elevation-z']) {
  box-shadow: $box-shadow;
}

.custom-border-card {
  border-radius: 10px;
  border: 2px solid $border-colour;
  padding: 16px;

  .mat-subheading-1,
  .mat-subheading-2 {
    color: $custom-border-card-font-colour;
  }
}

/** Product banner styles. */
.isa-banner {
  background-color: $isa;
  margin: -16px -16px 0 -16px !important; //Margin to offset padding of mat-card
  color: $white;
}

.gia-banner {
  background-color: $gia;
  margin: -16px -16px 0 -16px !important; //Margin to offset padding of mat-card
  color: $white;
}

.jisa-banner {
  background-color: $jisa;
  margin: -16px -16px 0 -16px !important; //Margin to offset padding of mat-card
  color: $white;
}

/** Accounts page banners. */
.summary-banner {
  background-color: $banner;
  margin: -16px -16px 0 -16px !important; //Margin to offset padding of mat-card
  height: 48px; //To align and match height of subnav item
}

.tooltip-style {
  font-size: 12px;
  color: $primary-background-color !important;
  background-color: $white-smoke;
  border: 1px solid $primary-background-color;
  font-weight: 600;
  margin: 0 !important;
}

.text-success {
  color: $green;
}

.text-danger {
  color: $red;
}
.mat-dialog-container {
  height: auto !important;
}
