@import 'client-colours';
/** Client specific variables. */
@font-face {
  font-family: 'Museo Sans';
  src: local('Museo Sans'), url('../../../assets/fonts/museo-sans/MuseoSans-100.otf') format('opentype'),
    url('../../../assets/fonts/museo-sans/MuseoSans-300.otf') format('opentype'),
    url('../../../assets/fonts/museo-sans/MuseoSans_500.otf') format('opentype'),
    url('../../../assets/fonts/museo-sans/MuseoSans_700.otf') format('opentype');
}

@font-face {
  font-family: 'Figtree';
  font-weight: 300;
  src: local('Figtree Light'), url('../../../assets/fonts/figtree/Figtree-VariableFont_wght.ttf') format('truetype');
}
@font-face {
  font-family: 'Times-New-Roman-Italic';
  font-weight: 300;
  src: local('Times-New-Roman-Italic'),
    url('../../../assets/fonts/figtree/Times-New-Roman-Italic.ttf') format('truetype');
}

$font-family: 'Figtree', sans-serif;
$control-border-radius: 15px;
$control-label-margin: 13.5px;
$control-input-padding: 10px;

/** Test MW styling. */

/** mat-button. */
button.mat-raised-button,
button.mat-raised-button.mat-primary,
button.mat-stroked-button,
button.mat-button,
a.mat-raised-button,
a.mat-raised-button.mat-primary,
a.mat-stroked-button,
a.mat-button {
  border-radius: $control-border-radius;
}

.mat-stroked-button,
.mat-stroked-button:not(.mat-button-disabled) {
  background-color: $input-background;
  color: $linkOrange;
  border: 1px solid transparent;
}

/** mat-form-field. */
.mat-form-field-infix {
  padding: 9px $control-input-padding 8px $control-input-padding !important;
  border-radius: $control-border-radius;
  background-color: $input-background;
  border-top: 0;
  margin-top: $control-label-margin;
  box-shadow: 0 1px #d3d3d3;
  color: $input-text;
}

.mat-select-value {
  color: $input-text;
}

.mat-form-field-suffix {
  display: flex;
  justify-content: flex-end;
  width: 0;
  right: 10px;
}

.mat-placeholder-required.mat-form-field-required-marker {
  margin-left: -3px;
}

.mat-form-field-label {
  padding-left: $control-input-padding;
}

.mat-form-field-underline {
  display: none;
}

.mat-stepper-header-icon-background-color,
.mat-step-icon-state-number{
  background-color: #072E27 !important;
}
.mat-step-icon-selected{
  background-color: $linkOrange !important;
}

.mat-error {
  padding-left: $control-input-padding;
}

/** mat-select. */
.mat-select-panel {
  background-color: $input-background;
  border-radius: $control-border-radius;
}

.mat-autocomplete-panel {
  background-color: $input-background;
  border-radius: $control-border-radius;
}

/** mat-checkbox. */
.mat-checkbox-background,
.mat-checkbox-frame {
  height: 20px;
  width: 20px;
  // border-radius: 50% !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $checkbox-background;
}

.mat-checkbox-inner-container {
  margin: 2px 8px auto !important;
}

/** Material card. */
.mat-card {
  border: none;
  border-radius: 0 !important;
}

.mat-card:not([class*='mat-elevation-z']) {
  box-shadow: none;
}

/** Material accordion and expansion panel */
.mat-accordion .mat-expansion-panel {
  box-shadow: none;
  border-radius: 0 !important;
}

.mainHeading {
  font-family: 'Times-New-Roman-Italic', Times, serif;
  color: $linkOrange;
  font-size: 40px;
}
.bold600 {
  font-weight: 500;
}

.mat-calendar-body-selected {
  background-color: $mcInRoyWhite;
  color: $linkOrange;
  border-color: $linkOrange;
}

@media (max-width: 400px) {
  .cdk-overlay-pane {
    bottom: 10px !important;
  }
}

// auto-fill
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  color: $inputText !important;
  transition: background-color 5000s ease-in-out 0s;
}

.info-icon,
.delete-icon {
  color: #072E27;
}

h3,
.h3 {
  color: $linkOrange !important;
}
